import React, { useState } from "react"
import { graphql } from 'gatsby'
import LayoutExport from "../components/layouts/layoutExport"
import Seo from '../components/Seo/';
import AsyncAdsPanel from '../components/ad/AsyncAdsPanel'
import { FormattedMessage } from 'react-intl'
import ModelMapLarge from '../components/Maps/ModelMapLarge'
import ModelStats from "../components/ModelStats"
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import ModelCharts from '../components/Charts/'


const ModelExtendedExport = (props) => {

  const
    [isAdsVisible, setAdsVisible] = useState(false),
    [isMapVisible, setMapVisible] = useState(false);

  const {
    name,
    slug,
    refUnivers,
    vipEmailExample,
    contentful_id,
    designer,
    launchYear,
    crew,
    weight
  } = props.data.model

  const {
    ads,
  } = props.data


  const { universID } = props.pageContext
  const title = {
    "fr":
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " d'occasion à vendre, achat/vente"
    ,
    "en":
      "Used " +
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " for sale, price/buying/selling a boat"
  };
  return (
    <LayoutExport
      lang={process.env.GATSBY_LANG}
    >
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          name +
          " " +
          refUnivers.name.toLowerCase() +
          " d'occasion et au meilleur prix à vendre en France et en Europe, annonces prix, achat, vente. Wanaboat.fr spécialiste achat/vente " +
          name +
          " d'occasion."
        }
        slug={slug}
      />
      <Box
        mx="auto"
        maxW='1200px'
      >
        <Tabs
          isFitted
          variant="enclosed"
          mb={{ base: 4, lg: 10 }}
        >
          <TabList
            pt='.25rem'
            pr='.25rem'
            pl='.25rem'
          >
            <Tab
              _selected={{ bg: "gray.50", borderColor: 'gray.300', borderBottom: 'none' }}
            >
              <FormattedMessage id="model.export.tab.ads" />
            </Tab>
            <Tab
              display={{ base: 'none', sm: 'flex' }}
              _selected={{ bg: "gray.50", borderColor: 'gray.300', borderBottom: 'none' }}
            >
              <FormattedMessage id="model.export.tab.map" />
            </Tab>
            <Tab
              _selected={{ bg: "gray.50", borderColor: 'gray.300', borderBottom: 'none' }}
            >
              <FormattedMessage
                id="model.export.tab.market"
                values={{ modelName: name }}
              />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel mt='0px'>
              <AsyncAdsPanel
                baseSlug={slug}
                minPrice={0}
                maxPrice={1000}
                universID={universID}
                modelID={contentful_id}
                // models={models}
                isModelsMobileOpen={false}
                openSelf={true}
                scrolling={false}
                switchViewForBrowsing={() => { this.setState({ isUserFilters: true }) }}
                context='iframe'
              />
            </TabPanel>
            <TabPanel>
              <ModelMapLarge ads={ads} />
            </TabPanel>
            <TabPanel>
              <ModelCharts ads={ads} modelID={contentful_id} name={name} />
              <ModelStats
                ads={ads}
                modelName={name}
                universName={refUnivers ? refUnivers.singularName ? refUnivers.singularName.toLowerCase() : '' : ''}
                launchYear={launchYear}
                boatDesigner={designer}
                boatCrew={crew}
                boatWeight={weight ? weight.toString() : null}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </LayoutExport>
  )
}

export default ModelExtendedExport

export const pageQuery = graphql`
  query modelExtendedExportQuery($slug: String!, $modelID: String!) {
    model: contentfulModel(id: { eq: $modelID }) {
      contentful_id
      slug
      name
      image
      vipEmailExample
      designer
      crew
      weight
      refUnivers {
        name
        slug
        singularName
      }
      refVersions {
        id
        name
        slug
      }
      refBrand {
        name
      }
      modelCharacteristics {
        data {
          key
          value
        }
      }
      refVersions {
        name
        slug
      }
      stolenData {
        name
        mail
        phone
        date
        content
      }
    }
    ads: allContentfulAd(
      filter: { refModel: { slug: { eq: $slug } } }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          refBrand {
            name
          }
          images {
            url
          }
          user{
            id
          }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
          location {
            lat
            lon
          }
        }
      }
    }
  }
`;