import React, { useState } from 'react'
// import { navigate } from "gatsby";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import { Box } from '@chakra-ui/react'
import AdCardLandscapeSmaller from '../../components/ad/cardLandscapeSmaller'
import AdLoader from '.././ad/AdLoader'

const ModelMapLarge = (props) => {
    const { ads } = props;
    const [adVisibleID, setAdVisibleID] = useState(null)
    const CustomMarker = props => {
        const { slug } = props;
        // console.log(props)
        // const onMarkerClick = evt => {
        //     navigate(`/${slug}`);
        // };
        if (props.location == null) {
            return null;
        } else {
            return (
                <>
                    <Marker
                        position={{
                            lat: props.location.lat,
                            lng: props.location.lon
                        }}
                        id={props.index}
                        label={props.price ? props.price + "€" : null}
                        label-color="white"
                        labelStyle={{ size: "10px" }}
                        title={props.name}
                        clickable={true}
                        data-slug={props.slug}
                        // onClick={onMarkerClick}
                        onClick={() => { setAdVisibleID(props.contentful_id) }}
                        zIndex={props.index}
                    />
                    {/* {adVisibleID === props.index ?

                        <InfoWindow
                            position={{
                                lat: props.location.lat,
                                lng: props.location.lon
                            }}
                        >
                            <AdCardLandscapeSmaller
                                name={props.ad.node.name}
                                images={props.ad.node.images}
                                key={props.ad.node.id}
                                region={props.ad.node.region}
                                price={props.ad.node.price}
                                to={props.ad.node.slug}
                            />

                        </InfoWindow>
                    : null} */}
                </>
            );
        }
    };
    const defaultOptions = {
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: true,
        draggableCursor: "default",
        draggingCursor: "move"
    };
    return (
        <Box position='relative'>
            <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U"
            >
                {
                    adVisibleID ?
                        <Box
                            position='absolute'
                            top='0'
                            left='0'
                            zIndex='tooltip'
                            w='100%'
                            h='100%'
                            backgroundColor='rgba(40,40,40,.5)'
                            p='3rem'
                            onClick={() => { setAdVisibleID(null) }}
                        >
                            <Box>
                                <Box w='700px' mx='auto'>
                                    <AdLoader
                                        adID={adVisibleID}
                                        key={`adportrait-${adVisibleID}`}
                                        handleUnload={
                                            () => {
                                                setAdVisibleID(null)
                                            }
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                        : null
                }
                <GoogleMap
                    id="model-map"
                    mapContainerStyle={{
                        height: "800px",
                        width: "100%"
                    }}
                    zoom={4.85}
                    center={{
                        lat: 46.7111,
                        lng: 1.7191
                    }}
                    defaultOptions={defaultOptions}
                >
                    {ads.edges.map((ad, index) => (
                        <CustomMarker
                            key={ad.node.id}
                            location={ad.node.location}
                            price={ad.node.price}
                            name={ad.node.name}
                            slug={ad.node.slug}
                            contentful_id={ad.node.contentful_id}
                            ad={ad}
                            index={index}
                        />
                    ))}
                </GoogleMap>
            </LoadScript>
        </Box>
    )
}
export default ModelMapLarge;